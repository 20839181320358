import { transactionsConstants } from "../constants/transactionsConstants";

const initialState = {
  transactionsHistoryLoading: false,
  transactionsHistoryList: []
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case transactionsConstants.GET_TRANSACTIONS_HISTORY_LOADING:
      state.transactionsHistoryLoading = true;
      return {
        ...state
      }

    case transactionsConstants.GET_TRANSACTIONS_HISTORY_SUCCESS:
      state.transactionsHistoryLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.transactionDetails) {
        state.transactionsHistoryList = action.payload.data.payload
      } else {
        state.transactionsHistoryList = []
      }
      return {
        ...state
      }

    case transactionsConstants.GET_TRANSACTIONS_HISTORY_FAILURE:
      state.transactionsHistoryLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
