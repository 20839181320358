import axios from 'axios';
import browserHistory from '../utils/history'
import { createNotification } from '../utils/notifications';
import { env_const } from '../utils/env_const';

const token = localStorage.getItem('token');

const axiosAuth = axios.create({
  baseURL: env_const.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token && `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS'
  }
});

axiosAuth.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
)

axiosAuth.interceptors.response.use((response) => {
  if (response.data.error) {
    return Promise.reject(response);
  }
  return response;
}, (error) => {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem('token')
    createNotification('Session expire. Please login again!!', 'error')
    browserHistory.replace('/login')
  }
  return Promise.reject(error);
});

export default axiosAuth





