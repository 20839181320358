import { ticketConstants } from "../constants/ticketConstants";

const initialState = {
  ticketDetail: {},
  ticketLoading: false,
  updateTicketLoading: false,
  initiateTransferLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ticketConstants.GET_TICKET_DATA_LOADING:
      state.ticketLoading = true;
      return {
        ...state
      }

    case ticketConstants.GET_TICKET_DATA_SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.ticketDetail = action.payload.data.payload
      }
      state.ticketLoading = false;
      return {
        ...state
      }

    case ticketConstants.GET_TICKET_DATA_FAILURE:
      state.ticketLoading = false;
      return {
        ...state
      }

    case ticketConstants.UPDATE_TICKET_LOADING:
      state.updateTicketLoading = true;
      return {
        ...state
      }

    case ticketConstants.UPDATE_TICKET_SUCCESS:
      // if (action.payload && action.payload.data && action.payload.data.payload) {
      //   state.ticketDetail = action.payload.data.payload
      // }
      state.updateTicketLoading = false;
      return {
        ...state
      }

    case ticketConstants.UPDATE_TICKET_FAILURE:
      state.updateTicketLoading = false;
      return {
        ...state
      }

    case ticketConstants.INITIATE_TRANSFER_LOADING:
      state.initiateTransferLoading = true;
      return {
        ...state
      }

    case ticketConstants.INITIATE_TRANSFER_SUCCESS:
      state.initiateTransferLoading = false;
      return {
        ...state
      }

    case ticketConstants.INITIATE_TRANSFER_FAILURE:
      state.initiateTransferLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
