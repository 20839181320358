export const dashboardConstants = {
  GET_DASHBOARD_INFO_LOADING: 'GET_DASHBOARD_INFO_LOADING',
  GET_DASHBOARD_INFO_SUCCESS: 'GET_DASHBOARD_INFO_SUCCESS',
  GET_DASHBOARD_INFO_FAILURE: 'GET_DASHBOARD_INFO_FAILURE',

  GET_DASHBOARD_USERS_INFO_LOADING: 'GET_DASHBOARD_USERS_INFO_LOADING',
  GET_DASHBOARD_USERS_INFO_SUCCESS: 'GET_DASHBOARD_USERS_INFO_SUCCESS',
  GET_DASHBOARD_USERS_INFO_FAILURE: 'GET_DASHBOARD_USERS_INFO_FAILURE',

  GET_DASHBOARD_BOOKINGS_INFO_LOADING: 'GET_DASHBOARD_BOOKINGS_INFO_LOADING',
  GET_DASHBOARD_BOOKINGS_INFO_SUCCESS: 'GET_DASHBOARD_BOOKINGS_INFO_SUCCESS',
  GET_DASHBOARD_BOOKINGS_INFO_FAILURE: 'GET_DASHBOARD_BOOKINGS_INFO_FAILURE',

  GET_DASHBOARD_GRAPH_INFO_LOADING: 'GET_DASHBOARD_GRAPH_INFO_LOADING',
  GET_DASHBOARD_GRAPH_INFO_SUCCESS: 'GET_DASHBOARD_GRAPH_INFO_SUCCESS',
  GET_DASHBOARD_GRAPH_INFO_FAILURE: 'GET_DASHBOARD_GRAPH_INFO_FAILURE',

}