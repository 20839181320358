export const ticketsConstants = {
  GET_TICKETS_LOADING: 'GET_TICKETS_LOADING',
  GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
  GET_TICKETS_FAILURE: 'GET_TICKETS_FAILURE',

  SEARCH_TICKETS_LOADING: 'SEARCH_TICKETS_LOADING',
  SEARCH_TICKETS_SUCCESS: 'SEARCH_TICKETS_SUCCESS',
  SEARCH_TICKETS_FAILURE: 'SEARCH_TICKETS_FAILURE',

  DOWNLOAD_TICKETS_LOADING: 'DOWNLOAD_TICKETS_LOADING',
  DOWNLOAD_TICKETS_SUCCESS: 'DOWNLOAD_TICKETS_SUCCESS',
  DOWNLOAD_TICKETS_FAILURE: 'DOWNLOAD_TICKETS_FAILURE',

  TICKET_CSV_HEADER: [
    { label: 'Ticket Id', key: 'ticketId' },
    { label: 'Booking Reference', key: 'ticketDetails.bookingReferenceNumber' },
    { label: 'Seller Name', key: 'ticketDetails.sellerName' },
    { label: 'Seller Email', key: 'ticketDetails.sellerEmailId' },
    { label: 'Departure', key: 'flightDetails.outbound.departingFrom.airportCode' },
    { label: 'Arrival', key: 'flightDetails.outbound.arrivingAt.airportCode' },
    { label: 'Departure Date', key: 'flightDetails.outbound.departingFrom.departure' },
    { label: 'Return Date', key: 'flightDetails.inbound.departingFrom.departure' },
    { label: 'Currency', key: 'ticketDetails.currency' },
    { label: 'Sold Price', key: 'ticketDetails.prices.soldPrice' },
    { label: 'Status', key: 'ticketDetails.status' },
    { label: 'Buyer Name', key: 'ticketDetails.buyerName' },
  ],

  TICKET_STATUS_LIST: [
    'ACTIVE',
    'LISTED',
    'DELISTED',
    'PENDING',
    'PENDING_APPROVAL',
    'SOLD',
    'COMPLETED',
    'PAYMENT_PROCESSED',
    'EXPIRED',
    'DELETED',
  ]
}