import { dashboardConstants } from "../constants/dashboardConstants";

const initialState = {
  infoLoading: false,
  dashboardInfo: null,
  dashboardUsers: null,
  usersLoading: false,
  dashboardBookings: null,
  bookingsLoading: false,
  dashboardBookingsGraph: null,
  bookingsGraphLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_INFO_LOADING:
      state.infoLoading = true;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_INFO_SUCCESS:
      console.log(action)
      state.infoLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.dashboardInfo = action.payload.data.payload;
      }
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_INFO_FAILURE:
      state.infoLoading = false;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_USERS_INFO_LOADING:
      state.usersLoading = true;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_USERS_INFO_SUCCESS:
      console.log(action)
      state.usersLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.dashboardUsers = action.payload.data.payload;
      }
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_USERS_INFO_FAILURE:
      state.usersLoading = false;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_BOOKINGS_INFO_LOADING:
      state.bookingsLoading = true;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_BOOKINGS_INFO_SUCCESS:
      console.log(action)
      state.bookingsLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.dashboardBookings = action.payload.data.payload;
      }
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_BOOKINGS_INFO_FAILURE:
      state.bookingsLoading = false;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_GRAPH_INFO_LOADING:
      state.bookingsGraphLoading = true;
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_GRAPH_INFO_SUCCESS:
      console.log(action)
      state.bookingsGraphLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.dashboardBookingsGraph = action.payload.data.payload;
      }
      return {
        ...state
      }

    case dashboardConstants.GET_DASHBOARD_GRAPH_INFO_FAILURE:
      state.bookingsGraphLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
