import { ticketsConstants } from "../constants/ticketsConstants";

const initialState = {
  tickets: [],
  ticketsListLoading: false,
  downloadLoading: false,
  allTickets: []
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case ticketsConstants.GET_TICKETS_LOADING:
      state.ticketsListLoading = true;
      return {
        ...state
      }

    case ticketsConstants.GET_TICKETS_SUCCESS:
      state.ticketsListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.tickets = action.payload.data.payload
      }
      return {
        ...state
      }

    case ticketsConstants.GET_TICKETS_FAILURE:
      state.ticketsListLoading = false;
      return {
        ...state
      }

    case ticketsConstants.SEARCH_TICKETS_LOADING:
      state.ticketsListLoading = true;
      state.tickets = [];
      return {
        ...state
      }

    case ticketsConstants.SEARCH_TICKETS_SUCCESS:
      state.ticketsListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.tickets = action.payload.data.payload
      }
      return {
        ...state
      }

    case ticketsConstants.SEARCH_TICKETS_FAILURE:
      state.ticketsListLoading = false;
      return {
        ...state
      }

    case ticketsConstants.DOWNLOAD_TICKETS_LOADING:
      state.downloadLoading = true;
      return {
        ...state
      }

    case ticketsConstants.DOWNLOAD_TICKETS_SUCCESS:
      state.downloadLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.allTickets = action.payload.data.payload
      }
      return {
        ...state
      }

    case ticketsConstants.DOWNLOAD_TICKETS_FAILURE:
      state.downloadLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
