import { usersConstants } from "../constants/usersConstants";

const initialState = {
  usersListLoading: false,
  users: [],
  allUsers: [],
  downloadLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case usersConstants.GET_USERS_LIST_LOADING:
      state.usersListLoading = true;
      return {
        ...state
      }

    case usersConstants.GET_USERS_LIST_SUCCESS:
      state.usersListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.users = action.payload.data.payload
      }
      return {
        ...state
      }

    case usersConstants.GET_USERS_LIST_FAILURE:
      state.usersListLoading = false;
      return {
        ...state
      }

    case usersConstants.GET_SEARCHED_USERS_LOADING:
      state.usersListLoading = true;
      state.users = [];
      return {
        ...state
      }

    case usersConstants.GET_SEARCHED_USERS_SUCCESS:
      state.usersListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.users = action.payload.data.payload
      }
      return {
        ...state
      }

    case usersConstants.GET_SEARCHED_USERS_FAILURE:
      state.usersListLoading = false;
      return {
        ...state
      }

    case usersConstants.STATUS_CHANGE_REQUEST_LOADING:
      state.usersListLoading = true;
      return {
        ...state
      }

    case usersConstants.STATUS_CHANGE_REQUEST_SUCCESS:
      state.usersListLoading = false;
      return {
        ...state
      }

    case usersConstants.STATUS_CHANGE_REQUEST_FAILURE:
      state.usersListLoading = false;
      return {
        ...state
      }

    case usersConstants.DOWNLOAD_USERS_LOADING:
      state.downloadLoading = true;
      return {
        ...state
      }

    case usersConstants.DOWNLOAD_USERS_SUCCESS:
      state.downlaodLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.allUsers = action.payload.data.payload
      }
      return {
        ...state
      }

    case usersConstants.DOWNLOAD_USERS_FAILURE:
      state.downloadLoading = false;
      state.allUsers = [];
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
