import { userConstants } from "../constants/userConstants";

const initialState = {
  userDetail: {},
  userLoading: false,
  airlineAccounts: [],
  airlineLoading: false,
  tickets: [],
  ticketsLoading: false,
  imageURL: null,
  imageLoading: false,
  getProfileCallback: false,
  airlineCallback: false,
  refreshAirlines: false,
  changePasswordLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case userConstants.GET_USER_DETAILS_LOADING:
      state.userLoading = true;
      return {
        ...state
      }

    case userConstants.GET_USER_DETAILS_SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.userDetail = action.payload.data.payload
      }
      state.userLoading = false;
      state.getProfileCallback = false;
      return {
        ...state
      }

    case userConstants.GET_USER_DETAILS_FAILURE:
      state.userLoading = false;
      state.getProfileCallback = false;
      return {
        ...state
      }

    case userConstants.GET_USER_AIRLINE_ACCOUNTS_LOADING:
      state.airlineLoading = true;
      state.airlineCallback = false;
      return {
        ...state
      }

    case userConstants.GET_USER_AIRLINE_ACCOUNTS_SUCCESS:
      state.airlineLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.airlineAccounts = action.payload.data.payload
      }
      return {
        ...state
      }

    case userConstants.GET_USER_AIRLINE_ACCOUNTS_FAILURE:
      state.airlineLoading = false;
      return {
        ...state
      }

    case userConstants.REFRESH_AIRLINE_ACCOUNTS_LOADING:
      state.refreshAirlines = true;
      return {
        ...state
      }

    case userConstants.REFRESH_AIRLINE_ACCOUNTS_SUCCESS:
      state.refreshAirlines = false;
      state.airlineCallback = true;
      return {
        ...state
      }

    case userConstants.REFRESH_AIRLINE_ACCOUNTS_FAILURE:
      state.refreshAirlines = false;
      state.airlineCallback = true;
      return {
        ...state
      }

    case userConstants.GET_USER_TICKETS_LOADING:
      state.ticketsLoading = true;
      return {
        ...state
      }

    case userConstants.GET_USER_TICKETS_SUCCESS:
      state.ticketsLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.tickets = action.payload.data.payload
      }
      return {
        ...state
      }

    case userConstants.GET_USER_TICKETS_FAILURE:
      state.ticketsLoading = false;
      return {
        ...state
      }

    case userConstants.UPDATE_USER_DETAILS_LOADING:
      state.userLoading = true;
      return {
        ...state
      }

    case userConstants.UPDATE_USER_DETAILS_SUCCESS:
      state.userLoading = false;
      // if (action.payload && action.payload.data && action.payload.data.payload) {
      //   state.userData = action.payload.data.payload
      // }
      return {
        ...state
      }

    case userConstants.UPDATE_USER_DETAILS_FAILURE:
      state.getProfileCallback = true;
      state.userLoading = false;
      return {
        ...state
      }

    case userConstants.UPDATE_USER_IMAGE_LOADING:
      state.imageLoading = true;
      state.imageURL = null;
      return {
        ...state
      }

    case userConstants.UPDATE_USER_IMAGE_SUCCESS:
      state.imageLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.url) {
        state.imageURL = action.payload.data.payload.url
      }
      return {
        ...state
      }

    case userConstants.UPDATE_USER_IMAGE_FAILURE:
      state.imageLoading = false;
      return {
        ...state
      }

    case userConstants.CHANGE_PASSWORD_LOADING:
      state.changePasswordLoading = true;
      return {
        ...state
      }

    case userConstants.CHANGE_PASSWORD_SUCCESS:
      state.changePasswordLoading = false;
      return {
        ...state
      }

    case userConstants.CHANGE_PASSWORD_FAILURE:
      state.changePasswordLoading = false;
      return {
        ...state
      }

    case userConstants.CHANGE_ADMIN_PASSWORD_LOADING:
      state.changePasswordLoading = true;
      return {
        ...state
      }

    case userConstants.CHANGE_ADMIN_PASSWORD_SUCCESS:
      state.changePasswordLoading = false;
      return {
        ...state
      }

    case userConstants.CHANGE_ADMIN_PASSWORD_FAILURE:
      state.changePasswordLoading = false;
      return {
        ...state
      }





    default:
      return {
        ...state,
      };
  }
}
