export const usersConstants = {
  GET_USERS_LIST_LOADING: 'GET_USERS_LIST_LOADING',
  GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
  GET_USERS_LIST_FAILURE: 'GET_USERS_LIST_FAILURE',

  GET_SEARCHED_USERS_LOADING: 'GET_SEARCHED_USERS_LOADING',
  GET_SEARCHED_USERS_SUCCESS: 'GET_SEARCHED_USERS_SUCCESS',
  GET_SEARCHED_USERS_FAILURE: 'GET_SEARCHED_USERS_FAILURE',

  STATUS_CHANGE_REQUEST_LOADING: 'STATUS_CHANGE_REQUEST_LOADING',
  STATUS_CHANGE_REQUEST_SUCCESS: 'STATUS_CHANGE_REQUEST_SUCCESS',
  STATUS_CHANGE_REQUEST_FAILURE: 'STATUS_CHANGE_REQUEST_FAILURE',

  DOWNLOAD_USERS_LOADING: 'DOWNLOAD_USERS_LOADING',
  DOWNLOAD_USERS_SUCCESS: 'DOWNLOAD_USERS_SUCCESS',
  DOWNLOAD_USERS_FAILURE: 'DOWNLOAD_USERS_FAILURE',

  USERS_CSV_HEADER: [
    { label: 'User Id', key: 'id' },
    { label: 'Email', key: 'email' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Last Login Date', key: 'lastLoginOn' },
    { label: 'Registration Date', key: 'registeredOn' },
    { label: 'Status', key: 'status' },
  ],

  USERS_STATUS_LIST: ['ACTIVE', 'INACTIVE', 'SUSPENDED']

}