import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import Main from './Main';
import { NotificationContainer } from 'react-notifications';
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
import browserHistory from './utils/history'
import { env_const } from './utils/env_const'
import { getDashboardInfo } from './utils/getDashboardInfo'
import Amplify, { Storage } from 'aws-amplify'
import './App.scss';
//Required styling for React Notifications 
import 'react-notifications/lib/notifications.css';
//Required styling for React Table
import 'react-table/react-table.css';
//Required styling for React Widget
import 'react-widgets/dist/css/react-widgets.css';
//Required styling for React phone number input
import "react-datepicker/dist/react-datepicker.css";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: env_const.POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: env_const.AWS_REGION
  }
})

Storage.configure({
  AWSS3: {
    // Your bucket ARN
    bucket: env_const.BUCKET_NAME,
    // Specify the region your bucket was created in
    region: env_const.AWS_REGION
  }
})

momentLocaliser(moment)
const store = configureStore();


class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token && window.location.pathname !== '/dashboard') {
      getDashboardInfo(token).then(response => response.json()).then(responseJson => {
        if (responseJson.payload && responseJson.payload.pendingActions && responseJson.payload.pendingActions > 0) {
          document.body.classList.add('pending-action')
        }
      }).catch(err => {
        document.body.classList.remove('pending-action')
      })
    }
  }

  render() {
    return (
      <Provider store={store}>
        <NotificationContainer />
        <Router history={browserHistory}>
          {/*All routes resides in the Main component with interceptor*/}
          <Main />
        </Router>
      </Provider>
    );
  }
}

export default App;
