import { settingsConstants } from "../constants/settingsConstants";

const initialState = {
  adminList: [],
  adminListLoading: false,
  adminDetailLoading: false,
  adminDetail: {},
  updateDetailsLoading: false,
  imageURL: null,
  imageLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case settingsConstants.GET_ADMIN_LIST_LOADING:
      state.adminListLoading = true;
      return {
        ...state,
      };

    case settingsConstants.GET_ADMIN_LIST_SUCCESS:
      state.adminListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.adminList = action.payload.data.payload
      }
      return {
        ...state
      };

    case settingsConstants.GET_ADMIN_LIST_FAILURE:
      state.adminListLoading = false;
      return {
        ...state,
      };

    case settingsConstants.GET_SEARCHED_ADMIN_LOADING:
      state.adminListLoading = true;
      state.adminList = [];
      return {
        ...state
      }

    case settingsConstants.GET_SEARCHED_ADMIN_SUCCESS:
      state.adminListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.adminList = action.payload.data.payload
      }
      return {
        ...state
      }

    case settingsConstants.GET_SEARCHED_ADMIN_FAILURE:
      state.adminListLoading = false;
      return {
        ...state
      }

    case settingsConstants.GET_ADMIN_DETAILS_LOADING:
      state.adminDetailLoading = true;
      return {
        ...state,
      };

    case settingsConstants.GET_ADMIN_DETAILS_SUCCESS:
      state.adminDetailLoading = false;
      console.log(action.payload);
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.adminList = action.payload.data.payload
        let user = JSON.parse(localStorage.getItem('user'))
        user.profileImage = action.payload.data.payload.photo
        localStorage.setItem('user', JSON.stringify(user))
      }
      return {
        ...state
      };

    case settingsConstants.GET_ADMIN_DETAILS_FAILURE:
      state.adminDetailLoading = false;
      return {
        ...state,
      };

    case settingsConstants.UPDATE_PROFILE_IMAGE_LOADING:
      state.imageLoading = true;
      state.imageURL = null;
      return {
        ...state
      }

    case settingsConstants.UPDATE_PROFILE_IMAGE_SUCCESS:
      state.imageLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.url) {
        state.imageURL = action.payload.data.payload.url
      }
      return {
        ...state
      }

    case settingsConstants.UPDATE_PROFILE_IMAGE_FAILURE:
      state.imageLoading = false;
      return {
        ...state
      }

    case settingsConstants.UPDATE_ADMIN_DETAILS_LOADING:
      state.updateDetailsLoading = true;
      return {
        ...state
      }

    case settingsConstants.UPDATE_ADMIN_DETAILS_SUCCESS:
      state.updateDetailsLoading = false;
      return {
        ...state
      }

    case settingsConstants.UPDATE_ADMIN_DETAILS_FAILURE:
      state.updateDetailsLoading = false;
      return {
        ...state
      }

    case settingsConstants.ADD_ADMIN_LOADING:
      state.updateDetailsLoading = true;
      return {
        ...state
      }

    case settingsConstants.ADD_ADMIN_SUCCESS:
      state.updateDetailsLoading = false;
      return {
        ...state
      }

    case settingsConstants.ADD_ADMIN_FAILURE:
      state.updateDetailsLoading = false;
      return {
        ...state
      }

    case settingsConstants.ADMIN_STATUS_CHANGE_REQUEST_LOADING:
      state.adminListLoading = true;
      return {
        ...state
      }

    case settingsConstants.ADMIN_STATUS_CHANGE_REQUEST_SUCCESS:
      state.adminListLoading = false;
      return {
        ...state
      }

    case settingsConstants.ADMIN_STATUS_CHANGE_REQUEST_FAILURE:
      state.adminListLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
