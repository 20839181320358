import { feedbacksConstants } from "../constants/feedbacksConstants";

const initialState = {
  feedbacksListLoading: false,
  feedbacksList: []
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case feedbacksConstants.GET_FEEDBACKS_LIST_LOADING:
      state.feedbacksListLoading = true;
      return {
        ...state
      }

    case feedbacksConstants.GET_FEEDBACKS_LIST_SUCCESS:
      state.feedbacksListLoading = false;
      if (action.payload && action.payload.data && action.payload.data.payload) {
        state.feedbacksList = action.payload.data.payload
      } else {
        state.feedbacksList = []
      }
      return {
        ...state
      }

    case feedbacksConstants.GET_FEEDBACKS_LIST_FAILURE:
      state.feedbacksListLoading = false;
      return {
        ...state
      }

    default:
      return {
        ...state,
      };
  }
}
