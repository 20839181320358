export const ticketConstants = {

  GET_TICKET_DATA_LOADING: 'GET_TICKET_DATA_LOADING',
  GET_TICKET_DATA_SUCCESS: 'GET_TICKET_DATA_SUCCESS',
  GET_TICKET_DATA_FAILURE: 'GET_TICKET_DATA_FAILURE',

  UPDATE_TICKET_LOADING: 'UPDATE_TICKET_LOADING',
  UPDATE_TICKET_SUCCESS: 'UPDATE_TICKET_SUCCESS',
  UPDATE_TICKET_FAILURE: 'UPDATE_TICKET_FAILURE',

  INITIATE_TRANSFER_LOADING: 'INITIATE_TRANSFER_LOADING',
  INITIATE_TRANSFER_SUCCESS: 'INITIATE_TRANSFER_SUCCESS',
  INITIATE_TRANSFER_FAILURE: 'INITIATE_TRANSFER_FAILURE',

  ticketStatus: ['LISTED', 'DELISTED', 'SOLD', 'EXPIRED', 'INACTIVE', 'PROCESSED', 'PENDING', 'PENDING_APPROVAL', 'ACTIVE'],

  nameChangeStatus: ['PENDING', 'PENDING_APPROVAL', 'COMPLETED'],

  ticketClassList: ['Economy', 'Business'],

  mealsType: ['Veg', 'Non-veg'],

  AIRLINES: ['EasyJet', 'Norwegain']

};