export const transactionsConstants = {
  GET_TRANSACTIONS_HISTORY_LOADING: 'GET_TRANSACTIONS_HISTORY_LOADING',
  GET_TRANSACTIONS_HISTORY_SUCCESS: 'GET_TRANSACTIONS_HISTORY_SUCCESS',
  GET_TRANSACTIONS_HISTORY_FAILURE: 'GET_TRANSACTIONS_HISTORY_FAILURE',

  TRANSACTION_TYPE_LIST: [
    { name: 'DEBIT', value: 'Debit' },
    { name: 'CREDIT', value: 'Credit' }
  ],

  TYPE_LIST: [
    { name: 'PURCHASE', value: 'Purchase' },
    { name: 'REFUND TO BUYER', value: 'Refund_to_buyer' },
    { name: 'NAME CHANGE FEE', value: 'Name_change_fee' },
    { name: 'SELLER SUM', value: 'Seller_sum' }
  ],

  STATUS_LIST: [
    { name: 'PENDING', value: 'Pending' },
    { name: 'DONE', value: 'Done' },
    { name: 'REFUNDED', value: 'Refunded' }
  ]
}