import { authConstants } from "../constants/authConstants";

const initialState = {
  userData: null,
  loginLoading: false
};

export default function (state = initialState, action = {}) {
  switch (action.type) {

    case authConstants.LOGIN_REQUEST_LOADING:
      state.loginLoading = true;
      return {
        ...state,
      };

    case authConstants.LOGIN_REQUEST_SUCCESS:
      console.log('check the payload data', action.payload)
      if (action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.loginToken) {
        localStorage.setItem('token', action.payload.data.payload.loginToken);
        localStorage.setItem('user', JSON.stringify(action.payload.data.payload.user));
        localStorage.setItem('accountType', action.payload.data.payload.user.type);
        state.userData = action.payload.data.payload.user;
      }
      state.loginLoading = false;
      return {
        ...state
      };

    case authConstants.LOGIN_REQUEST_FAILURE:
      state.loginLoading = false;
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
}
