export const userConstants = {

  GET_USER_DETAILS_LOADING: 'GET_USER_DETAILS_LOADING',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  GET_USER_AIRLINE_ACCOUNTS_LOADING: 'GET_USER_AIRLINE_ACCOUNTS_LOADING',
  GET_USER_AIRLINE_ACCOUNTS_SUCCESS: 'GET_USER_AIRLINE_ACCOUNTS_SUCCESS',
  GET_USER_AIRLINE_ACCOUNTS_FAILURE: 'GET_USER_AIRLINE_ACCOUNTS_FAILURE',

  GET_USER_TICKETS_LOADING: 'GET_USER_TICKETS_LOADING',
  GET_USER_TICKETS_SUCCESS: 'GET_USER_TICKETS_SUCCESS',
  GET_USER_TICKETS_FAILURE: 'GET_USER_TICKETS_FAILURE',

  UPDATE_USER_DETAILS_LOADING: 'UPDATE_USER_DETAILS_LOADING',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

  UPDATE_USER_IMAGE_LOADING: 'UPDATE_USER_IMAGE_LOADING',
  UPDATE_USER_IMAGE_SUCCESS: 'UPDATE_USER_IMAGE_SUCCESS',
  UPDATE_USER_IMAGE_FAILURE: 'UPDATE_USER_IMAGE_FAILURE',

  REFRESH_AIRLINE_ACCOUNTS_LOADING: 'REFRESH_AIRLINE_ACCOUNTS_LOADING',
  REFRESH_AIRLINE_ACCOUNTS_SUCCESS: 'REFRESH_AIRLINE_ACCOUNTS_SUCCESS',
  REFRESH_AIRLINE_ACCOUNTS_FAILURE: 'REFRESH_AIRLINE_ACCOUNTS_FAILURE',

  CHANGE_PASSWORD_LOADING: 'CHANGE_PASSWORD_LOADING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  CHANGE_ADMIN_PASSWORD_LOADING: 'CHANGE_ADMIN_PASSWORD_LOADING',
  CHANGE_ADMIN_PASSWORD_SUCCESS: 'CHANGE_ADMIN_PASSWORD_SUCCESS',
  CHANGE_ADMIN_PASSWORD_FAILURE: 'CHANGE_ADMIN_PASSWORD_FAILURE',

  USER_STATUS_LIST: ['ACTIVE', 'INACTIVE', 'SUSPENDED'],

  COUNTRY_CODE_LIST: [
    '+972', '+93', '+355', '+213', '+1 684', '+376', '+244', '+1 264',
    '+1268', '+54', '+374', '+297', '+61', '+43', '+994', '+1 242',
    '+973', '+880', '+1 246', '+375', '+32', '+501', '+229', '+1 441',
    '+975', '+387', '+267', '+55', '+246', '+359', '+226', '+257', '+855',
    '+237', '+1', '+238', '+ 345', '+236', '+235', '+56', '+86', '+61', '+57',
    '+269', '+242', '+682', '+506', '+385', '+53', '+537', '+420', '+45', '+253',
    '+1 767', '+1 849', '+593', '+20', '+503', '+240', '+291', '+372', '+251', '+298',
    '+679', '+358', '+33', '+594', '+689', '+241', '+220', '+995', '+49', '+233',
    '+350', '+30', '+299', '+1 473', '+590', '+1 671', '+502', '+224', '+245', '+595',
    '+509', '+504', '+36', '+354', '+91', '+62', '+964', '+353', '+972', '+39', '+1 876',
    '+81', '+962', '+7 7', '+254', '+686', '+965', '+996', '+371', '+961', '+266', '+231',
    '+423', '+370', '+352', '+261', '+265', '+60', '+960', '+223', '+356', '+692', '+596',
    '+222', '+230', '+262', '+52', '+377', '+976', '+382', '+1664', '+212', '+95', '+264',
    '+674', '+977', '+31', '+599', '+687', '+64', '+505', '+227', '+234', '+683', '+672',
    '+1 670', '+47', '+968', '+92', '+680', '+507', '+675', '+595', '+51', '+63', '+48',
    '+351', '+1 939', '+974', '+40', '+250', '+685', '+378', '+966', '+221', '+381', '+248',
    '+232', '+65', '+421', '+386', '+677', '+27', '+500', '+34', '+94', '+249', '+597', '+268',
    '+46', '+41', '+992', '+66', '+228', '+690', '+676', '+1 868', '+216', '+90', '+993',
    '+1 649', '+688', '+256', '+380', '+971', '+44', '+1', '+598', '+998', '+678', '+681',
    '+967', '+260', '+263', '', 'null', '+591', '+673', '+61', '+243', '+225', '+500', '+44',
    '+379', '+852', '+98', '+44', '+44', '+850', '+82', '+856', '+218', '+853', '+389', '+691',
    '+373', '+258', '+970', '+872', '+262', '+7', '+590', '+290', '+1 869', '+1 758', '+590',
    '+508', '+1 784', '+239', '+252', '+47', '+963', '+886', '+255', '+670', '+58', '+84',
    '+1 284', '+1 340'
  ]
};