export const settingsConstants = {
  GET_ADMIN_LIST_LOADING: 'GET_ADMIN_LIST_LOADING',
  GET_ADMIN_LIST_SUCCESS: 'GET_ADMIN_LIST_SUCCESS',
  GET_ADMIN_LIST_FAILURE: 'GET_ADMIN_LIST_FAILURE',

  GET_ADMIN_DETAILS_LOADING: 'GET_ADMIN_DETAILS_LOADING',
  GET_ADMIN_DETAILS_SUCCESS: 'GET_ADMIN_DETAILS_SUCCESS',
  GET_ADMIN_DETAILS_FAILURE: 'GET_ADMIN_DETAILS_FAILURE',

  ADD_ADMIN_LOADING: 'ADD_ADMIN_LOADING',
  ADD_ADMIN_SUCCESS: 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAILURE: 'ADD_ADMIN_FAILURE',

  GET_SEARCHED_ADMIN_LOADING: 'GET_SEARCHED_ADMIN_LOADING',
  GET_SEARCHED_ADMIN_SUCCESS: 'GET_SEARCHED_ADMIN_SUCCESS',
  GET_SEARCHED_ADMIN_FAILURE: 'GET_SEARCHED_ADMIN_FAILURE',

  UPDATE_ADMIN_DETAILS_LOADING: 'UPDATE_ADMIN_DETAILS_LOADING',
  UPDATE_ADMIN_DETAILS_SUCCESS: 'UPDATE_ADMIN_DETAILS_SUCCESS',
  UPDATE_ADMIN_DETAILS_FAILURE: 'UPDATE_ADMIN_DETAILS_FAILURE',

  UPDATE_PROFILE_IMAGE_LOADING: 'UPDATE_PROFILE_IMAGE_LOADING',
  UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
  UPDATE_PROFILE_IMAGE_FAILURE: 'UPDATE_PROFILE_IMAGE_FAILURE',

  ADMIN_STATUS_CHANGE_REQUEST_LOADING: 'ADMIN_STATUS_CHANGE_REQUEST_LOADING',
  ADMIN_STATUS_CHANGE_REQUEST_SUCCESS: 'ADMIN_STATUS_CHANGE_REQUEST_SUCCESS',
  ADMIN_STATUS_CHANGE_REQUEST_FAILURE: 'ADMIN_STATUS_CHANGE_REQUEST_FAILURE',

  COUNTRY_LIST: [
    {
      text: 'Austria',
      key: 'AT',
      value: 'AT',
      currency: 'EUR'
    },
    {
      text: 'Belgium',
      key: 'BE',
      value: 'BE',
      currency: 'EUR'
    },
    {
      text: 'Denmark',
      key: 'DK',
      value: 'DK',
      currency: 'DKK'
    },
    {
      text: 'Finland',
      key: 'FI',
      value: 'FI',
      currency: 'EUR'
    },
    {
      text: 'France',
      key: 'FR',
      value: 'FR',
      currency: 'EUR'
    },
    {
      text: 'Germany',
      key: 'DE',
      value: 'DE',
      currency: 'EUR'
    },
    {
      text: 'Ireland',
      key: 'IE',
      value: 'IE',
      currency: 'EUR'
    },
    {
      text: 'Italy',
      key: 'IT',
      value: 'IT',
      currency: 'EUR'
    },
    {
      text: 'Luxembourg',
      key: 'LU',
      value: 'LU',
      currency: 'EUR'
    },
    {
      text: 'Netherlands',
      key: 'NL',
      value: 'NL',
      currency: 'EUR'
    },
    {
      text: 'Norway',
      key: 'NO',
      value: 'NO',
      currency: 'NOK'
    },
    {
      text: 'Portugal',
      key: 'PT',
      value: 'PT',
      currency: 'EUR'
    },
    {
      text: 'Spain',
      key: 'ES',
      value: 'ES',
      currency: 'EUR'
    },
    {
      text: 'Sweden',
      key: 'SE',
      value: 'SE',
      currency: 'SEK'
    },
    {
      text: 'Switzerland',
      key: 'CH',
      value: 'CH',
      currency: 'CHF'
    },
    {
      text: 'United Kingdom',
      key: 'GB',
      value: 'GB',
      currency: 'GBP'
    }
  ],
  CURRENCY_LIST: ['INR', 'USD', 'GBP'],
  ROLE_LIST: ['Admin']
}
