import { NotificationManager } from 'react-notifications';

export function createNotification(msg, type, duration, subText) {

  if (type === 'info') {
    return NotificationManager.info(msg, subText, duration);
  } else if (type === 'success') {
    return NotificationManager.success(msg, subText, duration);
  } else if (type === 'warning') {
    return NotificationManager.warning(msg, subText, duration);
  } else if (type === 'error') {
    return NotificationManager.error(msg, subText, duration);
  } else {
    return NotificationManager.info(msg, subText, duration);
  }

};