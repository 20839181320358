import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import ticketsReducer from './ticketsReducer';
import usersReducer from './usersReducer';
import userReducer from './userReducer';
import ticketReducer from './ticketReducer';
import settingsReducer from './settingsReducer';
import dashboardReducer from './dashboardReducer';
import feedbacksReducer from './feedbacksReducer';
import transactionsReducer from './transactionsReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  form: formReducer,
  tickets: ticketsReducer,
  users: usersReducer,
  user: userReducer,
  ticket: ticketReducer,
  settings: settingsReducer,
  feedbacks: feedbacksReducer,
  transaction: transactionsReducer
});

export default rootReducer;
